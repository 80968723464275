/* Header */

#header {
  background-color: var(--white);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.header-text {
  width: 350px;
}

.header-text p {
  color: var(--gray);
  font-size: 18px;
  margin-bottom: 30px;
}

.header-img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  object-fit: cover;
  object-position: center bottom;
}

.header-text h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}

.header-text span {
  display: inline-block;
}

.btn-group {
  display: flex;
  gap: 20px;
}

.btn-contact {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.btn-contact:hover {
  opacity: 0.8;
}

.btn-black {
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}

.btn-black:hover {
  opacity: 0.8;
}

.project {
  max-width: 370px;
  background-color: var(--project-card-bg);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.project-img {
  border-radius: 10px;
}

.project-title {
  padding: 15px 20px 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  color: var(--project-card-text);
  text-align: center;
}

.project-description {
  padding: 5px 20px 20px;
  font-size: 18px;
  line-height: 1.3;
  color: var(--gray);
  text-align: center;
}

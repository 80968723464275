/* About */

#about {
  background-color: var(--whitepure);
}

#about-img {
  border-radius: 15px;
  box-shadow: var(--box-shadow);
}

.about {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about-text {
  width: 40%;
}

.about-text p {
  color: var(--gray);
  font-size: 18px;
}

.about-text h3 {
  color: var(--accent);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

/* Btn outline */

.btn-outline {
  display: flex;
  column-gap: 10px;
  align-items: center;

  height: 48px;
  padding: 12px 20px;
  margin-top: 30px;

  border-radius: 5px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;

  transition: opacity 0.2s ease-in;
}

.btn-outline:hover {
  opacity: 0.8;
}

.btn-outline:active {
  position: relative;
  top: 1px;
}

@import "./reset.css";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

:root {
  /* Colors */
  --black: #171718;
  --blackpure: #000;

  --gray: #767676;

  --black-border: #26292d;
  --white: #f9f9f9;
  --whitepure: #fff;

  --blue: #147efb;

  /* Nav */
  --nav-bg: var(--whitepure);
  --nav-text: var(--black);

  /* Header */
  --header-bg: var(--white);
  --header-text: var(--white);

  /* Footer */
  --footer-bg: var(--black);
  --footer-text: var(--white);

  --page-bg: #f9f9f9;
  --text-color: var(--black);
  --accent: var(--blue);
  --title-1: var(--black);

  --project-card-bg: var(--white);
  --project-card-text: var(--black);
  --box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

html,
body {
  min-height: 100vh;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -0.5px;
}

#root {
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* General */

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.none {
  display: none !important;
}

.section {
  padding: 100px;
}

.title-1 {
  margin-bottom: 60px;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--title-1);
  text-align: center;
}

.title-2 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--title-1);
}

/* Btn */

.btn {
  display: inline-block;
  height: 48px;
  padding: 12px 28px;

  border-radius: 5px;
  background-color: var(--accent);
  color: var(--white);
  letter-spacing: 0.15px;
  font-size: 16px;
  font-weight: 500;

  transition: opacity 0.2s ease-in;
}

.btn:hover {
  opacity: 0.8;
}

.btn:active {
  position: relative;
  top: 1px;
}

/* Project details */

.project-details {
  margin: 0 auto;
  max-width: 865px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.project-details-cover {
  max-width: 100%;
  margin-bottom: 40px;

  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.project-details-desc {
  margin-bottom: 30px;
  color: var(--gray);
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
}

.project-details-full-desc {
  /*width: 100%;*/
  margin-bottom: 30px;
  color: var(--gray);
  font-size: 18px;
}

@media (max-width: 620px) {
  /* General */

  .section {
    padding: 30px 0;
  }

  .title-1 {
    margin-bottom: 20px;
    font-size: 40px;
  }

  .title-2 {
    margin-bottom: 10px;
    font-size: 30px;
  }

  /* Nav */

  .nav-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .nav-row a {
    margin: 10px 0;
  }

  /* Header  */

  .header {
    flex-direction: column !important;
    align-items: center;
    text-align: center;
  }

  .header-text {
    max-width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }

  .header-img {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
  }

  .header-text h1 {
    font-size: 32px;
  }

  .header-text p {
    font-size: 16px;
  }

  .btn-group {
    flex-direction: column;
    gap: 10px;
  }

  /* About */

  .about {
    flex-direction: column;
    align-items: center;
  }

  .about-text {
    width: 100% !important;
    text-align: center;
    margin-top: 20px;
  }

  #about-img {
    margin: 10px;
  }

  /* Skills */

  .skills h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .skills-list {
    width: 100%;
  }

  .skills-container {
    flex-direction: column;
  }

  .skills {
    max-width: none;
    margin-bottom: 20px;
  }

  /* Projects */

  .project-title {
    font-size: 22px;
  }

  /* Project page */

  .project-details-desc {
    margin-bottom: 20px;
    font-size: 22px;
  }

  /* Contact */

  .contact-container {
    flex-direction: column-reverse;
  }

  .contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin: 30px;
  }

  .form-row {
    flex-direction: row;
  }

  .form-group {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .form-group:last-child {
    margin-right: 0;
  }

  .contact-form {
    width: 100% !important;
  }

  .contact-form button {
    width: 100% !important;
  }

  /* Footer */

  .footer {
    padding: 40px 0 30px;
  }

  .footer-wrapper {
    row-gap: 20px;
  }

  /* Social */

  .social {
    column-gap: 20px;
  }

  .social-item {
    width: 28px;
  }
}

/* Content category */

#skills {
  background-color: var(--white);
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 60px;
}

.skills {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  margin: 0 10px;
  text-align: center;
}

.skills h2 {
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 1.3;
  text-align: center;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

.skills-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
}

.skills-list-item p {
  color: var(--gray);
}

.skills-list-item img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.skills-list-item img:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

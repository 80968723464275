/* Nav */

.nav {
  padding: 20px 0;
  background-color: var(--nav-bg);
  color: var(--nav-text);
  letter-spacing: normal;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
}

.nav--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.logo {
  cursor: pointer;
  margin-right: auto;
  color: var(--nav-text);
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.logo strong {
  font-weight: 700;
}

.nav-list {
  display: flex;
  row-gap: 10px;
  align-items: center;
  column-gap: 40px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.nav-list-link {
  cursor: pointer;
  color: var(--nav-text);
  transition: opacity 0.2s ease-in;
}

.nav-list-link:hover {
  color: var(--accent);
  opacity: 0.8;
}

.nav-list-link--active {
  position: relative;
}

.nav-list-link--active::before {
  content: "";

  position: absolute;
  left: 0;
  top: 100%;

  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--accent);
}
